<template>
  <v-container fluid>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      max-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          label="Select Date"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :allowed-dates="allowedDates"
        no-title
        scrollable
      >
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="handleDate"> OK </v-btn>
      </v-date-picker>
    </v-menu>
    <v-col v-if="isHistoryLoading">
      <ProgressLinearLoaderVue :isLoading="isHistoryLoading" />
    </v-col>
    <v-row v-else>
      <v-col v-for="strategy in strategyTodaysHistory" :key="strategy.id">
        <StrategyExecutionHistoryCard :StrategyTodaysHistory="strategy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StrategyExecutionHistoryCard from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyExecutionHistoryCard.vue';
import { mapActions, mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

export default {
  name: 'StrategyExecutionHistoryList',

  components: {
    StrategyExecutionHistoryCard,
    ProgressLinearLoaderVue,
  },

  props: {
    StrategyTodaysHistory: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      searchTerm: 'StrategiesStore/StrategiesStore/getStrategySearchTerm',
      isHistoryLoading: 'StrategiesStore/StrategiesStore/getIsHistoryLoading',
      defaultSite: 'session/defaultSite',
    }),

    strategyTodaysHistory() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.StrategyTodaysHistory);
    },
  },

  created() {
    this.setStrategyExecutionHistoryList({
      siteId: this.defaultSite.siteId,
      strategyId: this.$route.params.strategyId,
    });
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['description', 'name', 'executionFrequency'],
        this.StrategyTodaysHistory
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    };
  },

  methods: {
    ...mapActions({
      setStrategyExecutionHistoryList:
        'StrategiesStore/StrategiesStore/getStrategyTodaysHistoryByStrategyId',
      setStrategyHistoryByDate:
        'StrategiesStore/StrategiesStore/getStrategyHistoryByDate',
    }),

    handleDate() {
      this.$refs.menu.save(this.date);
      this.menu = false;
      this.setStrategyHistoryByDate({
        siteId: this.defaultSite.siteId,
        strategyId: this.$route.params.strategyId,
        date: this.date,
      });
    },

    allowedDates(val) {
      const selectedDate = new Date(val).toISOString().slice(0, 10);
      const todayDate = new Date().toISOString().slice(0, 10);
      return selectedDate <= todayDate;
    },
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
