<template>
  <section style="height: 100%">
    <div v-if="hasSubmit">
      <v-btn @click="getReport">Get Report</v-btn>
    </div>
    <div
      style="height: 100%"
      class="mt-4"
      id="reportContainer"
      ref="reportContainer"
    ></div>
    <!-- <v-btn @click="refresco" class="mt-4" color="primary" dark>Refresh</v-btn> -->
  </section>
</template>

<style>
#reportContainer {
  width: 100%;
}

#reportContainer iframe {
  border: none;
}
</style>

<script>
import { refreshDefaultData } from '@/modules/reports/_factories/embedReports/defaultEmbed.js';

import api from '../_api';
import powerBiReportfactory from '../_factories/powerBiReportFactory.js';

export default {
  name: 'PowerBIReport',
  props: {
    reportName: { type: String, required: true },
    siteId: { type: String, required: false },
    hasSubmit: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: true },
    height: { type: Number, default: -1 },
    controlFriendlyName: { type: String, default: '' },
  },

  data() {
    return {
      embedConfiguration: null,
      refreshRate: 30000,
      isRealTimeRefreshing: false,
      timeoutId: null,
      isDashboard: false,
      intervalId: null,
    };
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  watch: {
    reportName: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== '') {
        this.getReport();
      }
    },
  },

  created() {
    this.getReport();

    const isRealtime = /Realtime/gi.test(this.reportName);

    if (isRealtime) {
      this.isRealTimeRefreshing = true;
    }

    if (this.isRealTimeRefreshing && !this.isDashboard) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        try {
          refreshDefaultData();
        } catch (error) {
          console.error('Error refreshing data: ', error);
        }
      }, this.refreshRate);
    }
  },

  methods: {
    getReport: async function () {
      const embedConfiguration = await api.getPowerBiEmbedConfiguration(
        this.reportName,
        this.siteId
      );

      this.embedConfiguration = embedConfiguration;

      if (embedConfiguration.type === 'dashboard') {
        this.isDashboard = true;
      } else {
        this.isDashboard = false;
      }

      if (this.controlFriendlyName) {
        embedConfiguration.reportParameters.SensorName =
          this.controlFriendlyName;
      }

      const container = document.getElementById('reportContainer');

      powerBiReportfactory.getPowerBiReportStrategy(
        this.reportName,
        container,
        embedConfiguration
      );
    },
  },
};
</script>
