'use strict';

import axios from 'axios';
import Qs from 'qs';
import { globalConfig } from '../configuration/config';
// Full config:  https://github.com/axios/axios#request-config

const access_token = localStorage.getItem('access_token');
axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

const _axios = axios.create();

_axios.defaults.paramsSerializer = (params) => {
  return Qs.stringify(params, { arrayFormat: 'repeat' });
};

_axios.interceptors.request.use(
  async function (config) {
    const apiType = config.headers['X-API-Type'];
    delete config.headers['X-API-Type'];

    if (apiType === 'analytics') {
      config.baseURL = globalConfig.get().DYNO_ANALYTICS_API_URL;
    } else if (apiType === 'sensor') {
      config.baseURL = globalConfig.get().DYNO_SENSOR_API_URL;
    } else {
      config.baseURL = globalConfig.get().DYNO_CORE_API_URL;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);

const AxiosPlugin = {
  async install(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;

    Object.defineProperties(Vue.prototype, {
      $http: {
        get() {
          return _axios;
        },
      },
      axios: {
        get() {
          return _axios;
        },
      },
      $axios: {
        get() {
          return _axios;
        },
      },
    });
  },
};

export default AxiosPlugin;
