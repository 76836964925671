<template>
  <div>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        {{ StrategyTodaysHistory.strategyName }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card class="list-card mx-auto">
      <v-expansion-panels accordion flat tile class="mt-n1">
        <!-- <v-subheader>
        {{ getDeviceFromStrategyInfo() }}
      </v-subheader> -->
        <v-expansion-panel
          v-for="(history, index) in removeKeysFromHistory"
          :key="index"
          no-action
        >
          <v-expansion-panel-header>
            <span class="font-weight-bold primary--text">
              {{ history.date }}
            </span>
            <v-tooltip bottom v-if="history.error">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="heatColor" v-bind="attrs" v-on="on">
                  mdi-alert-circle
                </v-icon>
              </template>
              <span> Has Errors </span>
            </v-tooltip>

            <v-tooltip bottom v-if="history.actionDetails">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="greenGraph" v-bind="attrs" v-on="on">
                  mdi-check-circle
                </v-icon>
              </template>
              <span> Has Actions </span>
            </v-tooltip>

            <template v-slot:actions>
              <v-btn depressed fab x-small active-class="sonicSilver">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content v-for="(value, key) in history" :key="key">
            <v-row v-if="value">
              <v-col cols="4">
                <span class="primary--text"> {{ splitCamelCase(key) }}: </span>
              </v-col>
              <v-col cols="8">
                <table v-if="isDevice(key)">
                  <tr
                    v-for="(deviceValue, deviceKey) in value"
                    :key="deviceKey"
                  >
                    <td>
                      <span class="primary--text">
                        {{ `${splitCamelCase(deviceKey)}: ${deviceValue}` }}
                      </span>
                    </td>
                  </tr>
                </table>
                <span v-else class="primary--text">
                  {{ mapValueByKey(value, key) }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { splitCamelCase, removeObjectKeys } from '@/Core.Patterns/Factory';
export default {
  name: 'StrategyExecutionHistoryCard',

  props: {
    StrategyTodaysHistory: {
      type: Object,
      required: true,
    },
  },

  computed: {
    removeKeysFromHistory() {
      const keysToRemove = ['logId'];

      const history = [...this.StrategyTodaysHistory.history];

      return history.map((historyItem) =>
        removeObjectKeys(historyItem, keysToRemove)
      );
    },
  },

  methods: {
    splitCamelCase,

    isDevice(key) {
      return key === 'device';
    },

    mapValueByKey(value, key) {
      if (key === 'actionDetails') {
        const keysToRemove = [
          'SiteId',
          'StrategyId',
          'StrategyName',
          'StrategyRunDateTime',
          'ControlId',
        ];
        return Object.entries(removeObjectKeys(JSON.parse(value), keysToRemove))
          .map(([key, value]) => `${splitCamelCase(key)}: ${value}`)
          .join(', ');
      } else {
        return value;
      }
    },

    getDeviceFromStrategyInfo() {
      console.log('StrategyTodaysHistory', this.StrategyTodaysHistory.history);
      return this.StrategyTodaysHistory.history.map(
        (historyItem) => historyItem.device
      );
    },
  },
};
</script>
<style lang="sass">
.list-card
  width: 100vw
  height: 800px
  overflow-y: scroll
</style>
