const getReportSearchTerm = (state) => state.reportSearchTerm;
const getScheduledReportsList = (state) => state.scheduledReportsList;
const getIsScheduledReportsListLoading = (state) =>
  state.isScheduledReportsListLoading;

export default {
  getReportSearchTerm,
  getScheduledReportsList,
  getIsScheduledReportsListLoading,
};
