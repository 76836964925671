//End Use Readings
//Site Slicer - vwSite - SiteName
//Date Slicer - vwReportingDatetime - Date
//UOM Slicer - UnitOfMeasureSelection - PowerBiFriendlyName
//End Use Slicer - vwEndUseNames - EndUseName

import constants from '../constants';

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(visuals);
  await setDateSlicerVisual(reportParameters, visuals);
};

const setSiteSlicerVisual = async (visuals) => {
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  const siteSlicerState = await siteSlicerVisual.getSlicerState();

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState(siteSlicerState);
  }
};

const setDateSlicerVisual = async (reportParameters, visuals) => {
  const dateSlicerFilter = {
    $schema: constants.schemaUrlAdv,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

export default {
  setSlicers,
};
