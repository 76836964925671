import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
import API from '@/Core.Service.Domain/Strategies/Strategies/API';

const getSitesWithStrategies = async (context) => {
  const sitesWithStrategies = await API.getSitesWithStrategies();
  context.commit('SITES_WITH_STRATEGIES', sitesWithStrategies);
};

const getStrategiesBySiteId = async (context, site) => {
  const { selectedSite } = site;

  const remappedSelectedSite = RemapObjectKeysAdapter(
    selectedSite,
    ['id'],
    ['siteId']
  );

  context.commit('SELECTED_SITE_STRATEGIES_IS_LOADING', true);

  const selectedSiteStrategies = await API.getStrategiesBySiteId(
    remappedSelectedSite
  );

  context.commit('SELECTED_SITE_STRATEGIES', selectedSiteStrategies);
  context.commit('SELECTED_SITE_STRATEGIES_IS_LOADING', false);
};

const setStrategyCurrentRoute = async (context, strategyCurrentRoute) => {
  context.commit('STRATEGY_CURRENT_ROUTE', strategyCurrentRoute);
};

const getStrategyTodaysHistoryByStrategyId = async (context, strategy) => {
  context.commit('IS_HISTORY_LOADING', true);
  try {
    const { siteId, strategyId } = strategy;
    const strategyTodaysHistory =
      await API.getStrategyTodaysHistoryByStrategyId(siteId, strategyId);

    context.commit('STRATEGY_TODAYS_HISTORY', strategyTodaysHistory);

    return strategyTodaysHistory;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    context.commit('IS_HISTORY_LOADING', false);
  }
};

const getStrategyHistoryByDate = async (context, strategy) => {
  //GET https://alpha-api.energy360.net/v1/strategy/site/{site-id}/{strategy-id}/history?date=2024-04-01

  context.commit('IS_HISTORY_LOADING', true);
  try {
    const { siteId, strategyId, date } = strategy;
    const strategyHistory = await API.getStrategyHistoryByDate(
      siteId,
      strategyId,
      date
    );

    context.commit('STRATEGY_TODAYS_HISTORY', strategyHistory);

    return strategyHistory;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    context.commit('IS_HISTORY_LOADING', false);
  }
};

export default {
  getSitesWithStrategies,
  getStrategiesBySiteId,
  setStrategyCurrentRoute,
  getStrategyTodaysHistoryByStrategyId,
  getStrategyHistoryByDate,
};
