const REPORT_SEARCH_TERM = (state, reportSearchTerm) => {
  state.reportSearchTerm = reportSearchTerm;
};

const SET_SCHEDULED_REPORTS_LIST = (state, scheduledReportsList) => {
  state.scheduledReportsList = scheduledReportsList;
};

const SET_SCHEDULED_REPORTS_LIST_LOADING = (
  state,
  isScheduledReportsListLoading
) => {
  state.isScheduledReportsListLoading = isScheduledReportsListLoading;
};

export default {
  REPORT_SEARCH_TERM,
  SET_SCHEDULED_REPORTS_LIST,
  SET_SCHEDULED_REPORTS_LIST_LOADING,
};
