<template>
  <v-container fluid>
    <v-row class="ml-1">
      <ReportsBar />
    </v-row>
    <v-row class="mt-12">
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col>
        <ReportsList :reportsList="reportsList" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/Core.Service.Domain/ViewSupport/Reports/API';
import ReportsList from '@/Core.Service.Domain/ViewSupport/Reports/Components/ReportsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import ReportsBar from '@/Core.Service.Domain/ViewSupport/Reports/Components/ReportsBar.vue';

export default {
  name: 'ReportsIndex',

  components: { ReportsList, ProgressLinearLoaderVue, ReportsBar },

  data() {
    return {
      reportsList: [],
      cardWidth: 370,
      cardHeight: 576,
      isLoading: true,
    };
  },

  async created() {
    try {
      this.reportsList = await API.getReports();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
