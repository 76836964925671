import actions from '@/Core.Service.Domain/ViewSupport/Reports/Store/actions.js';

import getters from '@/Core.Service.Domain/ViewSupport/Reports/Store/getters.js';
import mutations from '@/Core.Service.Domain/ViewSupport/Reports/Store/mutations.js';

export default {
  namespaced: true,
  state: {
    reportSearchTerm: '',
    scheduledReportsList: [],
    isScheduledReportsListLoading: false,
  },
  actions,
  getters,
  mutations,
};
