//Thermostat Readings
//Site Slicer
//Date Slicer
//Thermostat Slicer
//C02 / Temp Slicer
//CO2 Exceptions
//Site Slicer
//Date Slicer
//Thermostat Slicer
import cons from '../constants';

const setSlicers = async (reportParameters, visuals) => {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setThermostatSlicerVisual(reportParameters, visuals);
};

const setSiteSlicerVisual = async (reportParameters, visuals) => {
  const siteSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };

  const siteSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

const setDateSlicerVisual = async (reportParameters, visuals) => {
  const dateSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

const setThermostatSlicerVisual = async (reportParameters, visuals) => {
  const thermostatSlicerFilter = {
    $schema: cons.schemaUrlAdv,
    target: {
      table: 'vwThermostatUsageData',
      column: 'ControlFriendlyName',
    },
    operator: 'Top',
    itemCount: 1,
    orderBy: {
      table: 'vwThermostatUsageData',
      measure: 'ControlFriendlyName',
    },
    filterType: reportParameters.SensorName ? 1 : 5,
    values: [reportParameters.SensorName],
  };
  const thermostatSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Thermostat Slicer';
  })[0];

  if (thermostatSlicerVisual) {
    await thermostatSlicerVisual.setSlicerState({
      filters: [thermostatSlicerFilter],
    });
  }
};

export default {
  setSlicers,
};
