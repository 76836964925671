<template>
  <div>
    <StrategyExecutionHistoryList
      :StrategyTodaysHistory="strategyTodaysHistory"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StrategyExecutionHistoryList from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyExecutionHistoryList.vue';

export default {
  name: 'StrategiesExecutionHistoryListView',
  components: {
    StrategyExecutionHistoryList,
  },
  computed: {
    ...mapGetters({
      strategyTodaysHistory:
        'StrategiesStore/StrategiesStore/getStrategyTodaysHistory',
    }),
  },
};
</script>
